import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import base64svg from "./icons/base64svg"
import ArrowBandaid from "./icons/ArrowBandaid"

const arrowBandaid = base64svg(<ArrowBandaid dir="right" color="gray" />)

const StyledLink = styled(Link)`
  position: relative;
  display: block;
  height: 3.5rem;
  width: 40em;
  max-width: calc(100% - 2rem);
  background: white;
  margin: 0.6em 1em;
  border-radius: 5px;
  overflow: hidden;
  color: white;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-shadow: ${props => props.theme.shadows.flatter};
  will-change: transform, box-shadow;
  ${props => props.theme.animations.transitionBase}

  :hover {
    transform: translateY(-0.1875rem);
    box-shadow: ${props => props.theme.shadows.normal};
  }

  & > *,
  ::after {
    padding: .6rem;
  }

  ::after {
    content: "";
    height: 100%;
    display: flex;
    align-items: center;
    flex-basis: 3rem;
    box-sizing: border-box;
    background: url(${arrowBandaid}) no-repeat center center;
    background-size: 50%;
  }
`

const IconBox = styled.div`
  background: ${props => props.bgcolor ? props.bgcolor : `#ccc`};
  flex: 0 0 3.8rem;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    display: block;
    max-width: 90%;
    max-height: 90%;
    margin: auto;
  }
`

const Title = styled.span`
  flex-grow: 1;
  font-family: ${props => props.theme.headingFont};
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.1;
  ${props => props.color && `color: ${props.color};`}

  @media (max-width: 375px) {
    font-size: 1.05rem;
  }
`

const TeenageInfoThumbnail = ({ to, title, icon, color }) => {
  return (
    <StyledLink to={to}>
      <IconBox bgcolor={color}>
      {icon && <img src={icon} alt="" role="none" />}
      </IconBox>
      <Title color={color}>{title}</Title>
    </StyledLink>
  )
}

export default TeenageInfoThumbnail
