import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import RoomMock from "../components/RoomMock"
import RoomContentSection from "../components/RoomContentSection"
import RoomContentScroller from "../components/RoomContentScroller"
import FaqSection from "../components/FaqSection"
import TeenageInfoThumbnail from "../components/TeenageInfoThumbnail"
import MediaThumbnail from "../components/MediaThumbnail"
import VideoSection from "../components/VideoSection"
import QuestionPanel from "../components/QuestionPanel"

import getSlugFromString from "../utils/get-slug-from-string"
import { useModal } from "../hooks/modal"

import pictoMore from "../images/icons/Picto_Kors.svg"
import IconPlus from "./../components/icons/Plus"
import CaretUp from "./../components/icons/CaretUp"

const colors = {
  hex: {
    sandbrown: "#DECCA4",
    goldbrown: "#9F7640",
    turqoise: "#22817B",
    lila: "#93628C",
    blue: "#2179A2",
    oldrose: "#B75870",
    gray: "#7F7F7F",
    darkgray: "#3A3A3A"
  },
  rgb: {
    sandbrown: "222,204,164",
    goldbrown: "159,118,64",
    turqoise: "34, 129, 123",
    lila: "147,98,140",
    blue: "33,121,162",
    oldrose: "183, 88, 112",
    gray: "127, 127, 127",
    darkgray: "58,58,58"
  }
}

const IntroText = styled.div`
  margin: 0 1rem 2rem;
  width: 40rem;
  max-width: calc(100% - 2rem);
  font-weight: 600;
  line-height: 1.4736;
  padding-left: 1.25rem;
  border-left: 1px solid;
`

const FaqIntro = styled.div`
  margin: -1rem 1rem 1.25rem 1rem;
  width: 40rem;
  max-width: calc(100% - 2rem);
`

const TeenageInfoIntro = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0.4rem 1rem 1rem;
  width: 40rem;
  max-width: calc(100% - 2rem);
`

const Collapsible = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  transition: max-height .3s ease;

  p {
    margin-top: 0;
    text-overflow: ellipsis;
  }

  & > * {
    min-height: 0;
    flex: 1 2 auto;
    overflow: hidden;
    margin-bottom: 0;
    transform-origin: top;

    :first-child {
      flex: 2 0 auto;
      transform: scaleY(1);
      display: block;
      line-height: inherit;
    }

    :focus {
      outline: 0;
    }
  }

  &.collapsed > *:not(:first-child) {
    transform: scaleY(0);
    line-height: 0;
    margin-bottom: 0;
    transition:
      transform .3s ease,
      line-height .3s ease,
      margin-bottom .3s ease;
    animation: collapsible-collapse 0.3s forwards;
  }

  &.expanded {
    > * {
      line-height: inherit;
      margin-bottom: 0.6em;

      &:not(:first-child) {
        animation: collapsible-expand 0.3s forwards;
      }
    }
  }

  @keyframes collapsible-collapse {
    0% {
      transform: scaleY(1);
    }
    99% {
      visibility: visible;
      transform: scaleY(0);
    }
    100% { visibility: hidden; }
  }

  @keyframes collapsible-expand {
    0% {
      display: none;
      transform: scaleY(0);
    }
    1% {
      display: block;
      transform: scaleY(0);
    }
    100% { transform: scaleY(1); }
  }
`

const CollapsibleTrigger = styled.button`
  display: block;
  width: 100%;
  text-align: right;
  position: relative;
  padding: 0;
  border: 0;
  color: ${colors.hex.darkgray};
  background: none;
  font-size: 100%;
  line-height: 1.5;
  cursor: pointer;

  ::before {
    content: "...";
    position: absolute;
    left: 0;
    bottom: 0;
  }

  img {
    height: 1.2em;
    width: 1.2em;
    margin-left: 0.4rem;
    vertical-align: middle;
  }

  &.active {
    display: inline-block;
    width: auto;
    align-self: flex-end;

    ::before {
      content: "";
    }
  }
`

const TeenageInfoLinks = styled.ul`
  margin: 1.25rem 0 1.875rem;
  padding: 0;
  list-style: none;
`

export default function RoomTemplate7({
  data: {
    allTextsYaml,
    firstTeenageInfoYaml,
    restTeenageInfoYaml,
    allVideosYaml,
    allKlaraAndOssiYaml,
    allPodcastYaml,
    questionPanelYaml,
    ciceronYaml,
  },
  pageContext: {
    lang,
    pagePath,
    translations,
  },
}) {
  const intl = useIntl()
  const { openModal } = useModal()
  const [expanded, setExpanded] = useState(false)
  const [expandButton, setExpandButton] = useState(null)
  const introText = allTextsYaml.nodes.find((x) => x._id === "Intro 13+")
  const faqText = allTextsYaml.nodes.find((x) => x._id === "Intro 13+ FAQ")
  const teenageInfoIntro = firstTeenageInfoYaml.nodes[0]
  const needlesAndInjectionsVideos = allVideosYaml.nodes.find((x) => x._id === "needles-and-injections")
  const vlogVideos = allVideosYaml.nodes.find((x) => x._id === "nw-vlog")

  useEffect(() => {
    const secondChild = document.querySelector(".collapsible > *:nth-child(2)")

    if (secondChild) {
      setExpandButton(
        <CollapsibleTrigger onClick={() => setExpanded(!expanded)} className={expanded ? "active" : ""}>
          {expanded ? <>{intl.formatMessage({ id: "close" })} <CaretUp /> </> : <>{intl.formatMessage({ id: "showMore" })} <IconPlus fill={colors.hex.gray} /></>}
        </CollapsibleTrigger>
      )
    }
  }, [])

  useEffect(() => {
    if (expanded) {
      const secondChild = document.querySelector(".collapsible > *:nth-child(2)")
      secondChild.setAttribute("tabindex", "-1")
      setTimeout(() => { secondChild.focus() })
    }
  }, [expanded])

  return (
    <RoomMock
      lang={lang}
      path={pagePath}
      room={7}
      title={intl.formatMessage({ id: "roomHeading7" })}
      shareImage={{
        url: `/assets/share-images/NW_Delning9_13+_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
        width: 1200,
        height: 630,
      }}
      ciceronData={ciceronYaml}
      translations={translations}
    >
      {introText &&
        <IntroText dangerouslySetInnerHTML={{ __html: get(introText, "text.html") }} />
      }
      <RoomContentSection heading={intl.formatMessage({ id: "faqTeensHeading" })}>
        {faqText &&
          <FaqIntro dangerouslySetInnerHTML={{ __html: get(faqText, "text.html") }} />
        }
        <FaqSection lang={lang} room={7} colors={colors} />
      </RoomContentSection>
      <RoomContentSection heading={intl.formatMessage({ id: "readAboutAnaesthesiaAndOperation" })}>
        {teenageInfoIntro &&
          <TeenageInfoIntro>
            <Collapsible className={`collapsible ${expanded ? "expanded" : "collapsed"}`} dangerouslySetInnerHTML={{ __html: get(teenageInfoIntro, "text.html") }} />
            {expandButton}
          </TeenageInfoIntro>
        }
        <TeenageInfoLinks>
          {restTeenageInfoYaml.nodes.map((item) => (
            <li key={item.title}>
              <TeenageInfoThumbnail
                to={`/${lang}/${getSlugFromString(item.title)}`}
                title={item.title}
                color={item.thumbnailBgColor}
                icon={item.thumbnailIcon}
              />
            </li>
          ))}
        </TeenageInfoLinks>
      </RoomContentSection>
      <RoomContentSection heading={needlesAndInjectionsVideos.title}>
        <RoomContentScroller
          items={[
            <MediaThumbnail
              to={`/${lang}/${getSlugFromString(intl.formatMessage({ id: "mathildaSlideshowTitle" }))}/1`}
              title={intl.formatMessage({ id: "mathildaSlideshowTitle" })}
              background={`/assets/slideshow_covers/Cover_Mathilda_${lang === "sv" ? "Sve" : "Eng"}-min.png`}
              imageTransform
            />,
          ].concat((needlesAndInjectionsVideos.videos || []).map((video) => (
            <MediaThumbnail
              provider={video.provider}
              title={video.title}
              background={video.image}
              onClick={(e) => openModal(e, "video", {
                lang,
                id: video.id,
                title: video.title,
                audioDescription: video.audio_description,
              })}
              imageTransform
            />
          )))}
        />
      </RoomContentSection>
      <RoomContentSection heading={intl.formatMessage({ id: "blogs" })}>
        <RoomContentScroller
          items={allKlaraAndOssiYaml.nodes.map((blog) => (
            <MediaThumbnail
              to={`/${lang}${blog.path}`}
              background={blog.thumb}
              title={blog.title}
              imageTransform
            />
          ))}
        />
      </RoomContentSection>
      <RoomContentSection heading={vlogVideos.title}>
        <VideoSection
          videos={vlogVideos.videos}
          specialType="vlog"
          lang={lang}
          imageTransform
        />
      </RoomContentSection>
      {allPodcastYaml.nodes && allPodcastYaml.nodes.length > 0 &&
        <RoomContentSection heading={intl.formatMessage({ id: "podcast" })}>
          <RoomContentScroller
            items={allPodcastYaml.nodes.map((pod) => (
              ({ lazyBp }) => (
                <MediaThumbnail
                  to={`/${lang}/podcast/${getSlugFromString(pod.title)}`}
                  title={pod.title}
                  titleIsVisible={true}
                  background={pod.image}
                  specialType="podcast"
                  lazyBp={lazyBp}
                />
              )
            ))}
          />
        </RoomContentSection>
      }
      {questionPanelYaml &&
        <RoomContentSection heading={questionPanelYaml.title}>
          <QuestionPanel
            lang={lang}
            emailContactImage={questionPanelYaml.email_contact_image}
            questions={questionPanelYaml.questions} theme="dark"
          />
        </RoomContentSection>
      }
    </RoomMock>
  )
}

export const pageQuery = graphql`
  query($lang: String) {
    allTextsYaml(filter: { lang: { eq: $lang }, _id: { regex: "/.?13\\+.?/" } }) {
      nodes {
        _id
        text {
          html
        }
      }
    }
    firstTeenageInfoYaml: allTeenageInfoYaml(filter: { lang: { eq: $lang } }, sort: { fields: order }, limit: 1) {
      nodes {
        text {
          html
        }
      }
    }
    restTeenageInfoYaml: allTeenageInfoYaml(filter: { lang: { eq: $lang } }, sort: { fields: order }, skip: 1) {
      nodes {
        title
        thumbnailIcon
        thumbnailBgColor
      }
    }
    allVideosYaml(filter: { lang: { eq: $lang }, page: { eq: "13+" } }) {
      nodes {
        _id
        title
        videos {
          id
          provider
          title
          image
          audio_description
        }
      }
    }
    allKlaraAndOssiYaml(filter: { lang: { eq: $lang } }, sort: { fields: order }) {
      nodes {
        path
        title
        thumb
      }
    }
    allPodcastYaml(filter: { lang: { eq: $lang }, isPublished: { eq: true } }, sort: { fields: order }) {
      nodes {
        title
        image
      }
    }
    questionPanelYaml(lang: { eq: $lang }, _id: { eq: "question-panel" }) {
      title
      email_contact_image
      questions {
        question
        answers {
          name
          video_id
          image
        }
      }
    }
    ciceronYaml(lang: { eq: $lang }, _id: { eq: "rum-7" }) {
      text
      sound
    }
  }
`
